header.banner {

    background: transparent;
    height: auto; /*$navbar-height;*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: rgb(255,255,255);
    z-index: 100;

    box-shadow: none;
    border-bottom: none;

    .brand {
        float: left;
    }
    .logo {
        display: inline-block;
        width: auto;
        height: 80px;
        margin: 10px 0;
        @media(max-width: $screen-md-max) {
            height: 70px;
            margin: 10px 0 5px;
        }
    }
    .nav-primary {
        float: none;
        clear: right;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .in .nav-primary {
        position: static;
    }

    .navbar-header {
        position: relative;
        z-index: 100;
    }
}

#primary-nav {
    position: relative;
    z-index: 0;

    @media(max-width: $screen-md-max) {
    margin: 0 -15px;
    }
}
#search {
    position: absolute;
    right: 0;
    top: 100px;
    @media(max-width: $screen-md-max) {
        top: 80px;
    }
    background: lighten($brand-light,10%);
    border: none;
    border-top: 7px solid $brand-light;
    padding: 15px;
    font-family: $font-family-sans-serif;
    font-weight: 300;

    .search-submit {
        background: $brand-light;
        border-color: $brand-light;
        &:hover {
           background: $brand-dark;
           border-color: $brand-dark;
        }
    }
}
.search-button {
    font-size: 20px;
    line-height: 36px;
    padding: 0px 0px 0px 10px;
    height: 36px;
    display: block;

    &:focus,
    &:active,
    &:hover {
        background: $brand-light;
        color: #fff;
    }

    background: $brand-light;
    color: #fff;
    &.collapsed {
        color: $brand-dark;
        background: #fff;
        opacity: 0.7;
    }
    @media(max-width: $screen-md-max) {
        display: none;
    }

}


.admin-bar {
    header.banner {
        top: 32px;
    }
}

.nav > li > a { padding: 10px 10px;}

// navbar
.navbar-default {
    min-height: 1px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 7px solid $brand-light !important;

    text-transform: none;
    background: #fff; /*rgba(37,37,53,0.9);*/
    margin-bottom: 0 !important;

    font-family: $headerFont;
    .caret {
        display: none;
    }
    font-weight: 300;

    font-size: 20px;


    @media(max-width: 990px) {
        font-size: 18px;
    }
    @media(max-width: 900px) {
        font-size: 17px;
    }

    @media(max-width: $screen-xs-max) {
        font-size: 22px;
        min-height: 80px;
    }

    .navbar-nav {

        > li {
            > a {
                color: $brand-dark;
                padding: 7px 10px!important;
                @media(max-width: 900px) {
                    padding: 8px 7px!important;
                }
                @media(max-width: $screen-xs-max) {
                    padding: 8px 16px!important;
                }
            }

            padding: 0px 1px!important;
            @media(max-width: 820px) {
                padding: 0px 0px!important;
            }

            &:hover {
                > a {
                    background: $brand-light !important;
                    color: #fff;
                }
            }
        }

        > .open {
            > a,
            > a:focus,
            > a:hover {
                background: $brand-light !important;
                color: #fff !important;
            }
        }

        @media(min-width: $screen-sm-min) {
            > .active {
                > a,
                > a:focus,
                > a:hover {
                    background: $brand-light !important;
                    color: #fff !important;
                }
            }
        }
    }

    .dropdown:hover {
        > a {
            color: #fff;
        }
        .dropdown-menu {
            display: block;
            background: lighten($brand-light,10%);
            border: none;
            min-width: 100%;
            border-top: 7px solid $brand-light;
        }
    }
    @media(max-width: $screen-md-max) {
        .dropdown:not(.open) {
            .dropdown-menu {
                display: none;
            }
        }
    }



    .dropdown-menu {
        font-size: 20px;
        border-radius: 0;
        background: rgb(77,77,77);
        a {
            color: #fff;
            &:hover {
                color: #000;
            }
            background: transparent;
        }
    }

    /*.dropdown-menu>.current-page-parent>a ,*/
    .dropdown-menu>li>a {
        color: #fff !important;
    }
    .dropdown-menu>li>a,
    .dropdown-menu>li>a:hover,
    .dropdown-menu>li>a:focus,
    .dropdown-menu>.active>a,
    .dropdown-menu>.active>a:focus,
    .dropdown-menu>.active>a:hover {
        background: transparent;
    }

    .dropdown-menu>li>a:hover,
    .dropdown-menu>li>a:focus,
    .dropdown-menu>.active>a:focus,
    .dropdown-menu>.active>a:hover,
    .dropdown-menu>.current_page_item>a,
    .dropdown-menu>.current_page_item>a:focus,
    .dropdown-menu>.current_page_item>a:hover {
        color: #000 !important;

        @media(max-width: $screen-md-max) {
            color: $brand-light!important;
        }

    }
}
.navbar-toggle {
    border-color: $brand-primary;
    border:none;
    background: transparent !important;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 20px;
    z-index: 101;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: $brand-primary;
}
.menu-members {
    margin-top: 0;
}
.btn-login {
    @extend .btn-primary;
    font-family: $headerFont;
    font-weight: normal;
    font-size: 20px;
    padding: 1px 1.5em;
    display: block;
    z-index: 101;

    @media(max-width: $screen-md-max) {
        font-size: 19px;
    }
    @media(max-width: 990px) {
        font-size: 17px;
    }
}
.breadcrumb-container {
    float: right;
    margin-top: 25px;
    a {
        line-height: 1.3;
    }
    li > a+.separator {
        display: none;
    }
    @media(max-width: $screen-md-max) {
        display: none;
    }
}
#member-btn-wrapper {
    position: relative;
    z-index: 101;

    @media(max-width: $grid-float-breakpoint-max) {
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .navbar-default .navbar-right .sub-menu,
    .navbar-right .dropdown-menu,
    .navbar-right .navbar-default .sub-menu {
        right: auto;
        left: 1px;
    }
}

@media(max-width: $screen-xs-max) {
    .navbar-default {
        .navbar-nav {
            background: rgb(77,77,77);
            a {
                color: #fff;
                &:hover {
                    color: $brand-primary;
                }
                background: transparent;
            }
        }

        .navbar-nav>li>a {
            padding-left: 20px;
            padding-right: 20px;
            color: #fff;
            &:hover {
                color: $brand-primary;
            }
        }
    }
}
@media(max-width: $screen-sm-max) {
    .navbar-toggle {
        margin-top: 22px;
        margin-left: 10px;
        margin-right: 0px;
    }
    .navbar-nav>li>a {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media(min-width: $screen-md-min) {
    .navbar-default .navbar-right .sub-menu, .navbar-right .dropdown-menu, .navbar-right .navbar-default .sub-menu {
        left: 1px;
    }
}